<template>
  <S2SForm title="Transaction History">
    <v-row>
      <v-col cols="12" :sm="6">
        <v-text-field
          @click="
            dialogFromModal = true;
            showFilterBtn = true;
          "
          label="From Date"
          prepend-icon="event"
          :value="date.from_date"
          readonly
          clearable
          @click:clear="date.from_date = null"
        ></v-text-field>
        <v-dialog
          ref="dialog"
          v-model="dialogFromModal"
          persistent
          width="initial"
        >
          <div>
            <v-layout row wrap class="white" px-2 pb-2>
              <v-flex mr-2>
                <h4
                  style="background-color: white"
                  class="py-2 pl-2 mt-2 text-center"
                >
                  Date: {{ date.from_date }}
                </h4>
                <v-date-picker
                  v-model="date.from_date"
                  type="date"
                  scrollable
                  no-title
                  :min="startDate"
                ></v-date-picker>
              </v-flex>
            </v-layout>

            <v-flex>
              <v-card color="background" height="50px">
                <v-btn
                  text
                  color="primary"
                  @click="dialogFromModal = false"
                  block
                  min-height="100%"
                  >Done</v-btn
                >
              </v-card>
            </v-flex>
          </div>
        </v-dialog>
      </v-col>
      <v-col cols="12" :sm="6">
        <v-text-field
          @click="
            dialogToModal = true;
            showFilterBtn = true;
          "
          label="To Date"
          prepend-icon="event"
          :value="date.to_date"
          readonly
          clearable
          @click:clear="date.to_date = null"
        ></v-text-field>
        <v-dialog
          ref="dialog"
          v-model="dialogToModal"
          persistent
          width="initial"
        >
          <div>
            <v-layout row wrap class="white" px-2 pb-2>
              <v-flex mr-2>
                <h4
                  style="background-color: white"
                  class="py-2 pl-2 text-center"
                >
                  Date: {{ date.to_date }}
                </h4>
                <v-date-picker
                  v-model="date.to_date"
                  type="date"
                  scrollable
                  no-title
                ></v-date-picker>
              </v-flex>
            </v-layout>

            <v-flex>
              <v-card color="background" height="50px">
                <v-btn
                  text
                  color="primary"
                  @click="dialogToModal = false"
                  block
                  min-height="100%"
                  >Done</v-btn
                >
              </v-card>
            </v-flex>
          </div>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="end" v-if="advanced">
      <v-col cols="12" :sm="cols">
        <v-select
          v-model="directionModel"
          :items="directionItems"
          item-text="text"
          item-value="value"
          label="To/From"
          class="mr-3"
          @change="showFilterBtn = true"
        >
        </v-select>
      </v-col>
      <v-col cols="12" :sm="cols">
        <v-select
          v-model="typesModel"
          :items="typeItems"
          item-text="text"
          item-value="value"
          label="Type"
          @change="checkConditional()"
          class="mr-3"
        >
        </v-select>
      </v-col>

      <v-col cols="12" :sm="cols" v-if="showFilterSelect1">
        <v-text-field
          label="Search Organisation by Code"
          v-model="searchString"
          append-icon="search"
          @keydown.enter="searchOrg"
          @click:append="searchOrg"
          hide-details
          single-line
          clearable
          @click:clear="clearOrg"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" :sm="cols" v-if="searchedOrganisation">
        <v-text-field
          label="Organisation"
          v-model="searchedOrganisation"
          hide-details
          single-line
          readonly
          filled
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" :sm="cols" v-if="showFilterInput1">
        <v-text-field
          class="mr-3"
          :label="filterInput1Label"
          v-model="filterInput1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" :sm="cols" v-if="showFilterInput2">
        <v-text-field
          class="mr-3"
          :label="filterInput2Label"
          v-model="filterInput2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :sm="cols">
        <v-btn
          v-if="showFilterBtn"
          @click="filterTransactions()"
          :loading="loading"
          >Filter</v-btn
        >
        <v-btn v-if="showResetBtn" @click="resetFilters()" class="ml-3"
          >Reset</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          class="ma-0"
          label="show more"
          @change="advanced = !advanced"
          v-if="!advanced"
          hide-details
        ></v-checkbox>
        <v-checkbox
          class="ma-0"
          label="show less"
          @change="advanced = !advanced"
          v-if="advanced"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div v-if="showResetBtn">
          <div class="subtitle-2 d-inline mr-2">Active Filters:</div>
          <v-chip
            v-for="filter in showFilters"
            :key="filter.id"
            class="mr-1"
            color="secondary"
            text-color="white"
            x-small
            pill
            >{{ filter }}</v-chip
          >
        </div>
        <div v-else>
          <div class="subtitle-2 d-inline mr-2">Active Filters:</div>
          <v-chip class="mr-1" color="secondary" text-color="white" x-small pill
            >Both</v-chip
          >
          <v-chip class="mr-1" color="secondary" text-color="white" x-small pill
            >Any</v-chip
          >
        </div>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="transactions"
      :options.sync="_pagination"
      :server-items-length="totalItems"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'show-current-page': true,
        'page-text': '',
      }"
    >
      <template v-slot:item.created="{ item }">{{
        formatDate(item.created)
      }}</template>
      <template v-slot:item.amount="{ item }">
        <v-layout align-center justify-space-between>
          <span v-if="isAmountPositive(item)">{{
            formatCurrency(item.amount)
          }}</span>
          <span v-else>{{ formatCurrency(-item.amount) }}</span>
          <v-icon
            class="float-right"
            size="30"
            :color="isAmountPositive(item) ? 'green darken-1' : 'red darken-2'"
            >{{
              isAmountPositive(item) ? " arrow_drop_up" : "arrow_drop_down"
            }}</v-icon
          >
        </v-layout>
      </template>
      <template v-slot:item.profit="{ item }">
        {{ formatCurrency(item.profit) }}
      </template>
      <template v-slot:item.fee="{ item }">
        {{ formatCurrency(item.fee) }}
      </template>
      <template v-slot:item.statusName="{ item }">
        <span :class="getStatusColor(item.statusName)">{{
          item.statusName
        }}</span>
      </template>
      <template v-slot:item.reference="{ item }">
        {{ item.reference ? item.reference : "-" }}
      </template>
      <template v-slot:item.payee="{ item }">
        {{ getPayee(item) }}
      </template>
    </v-data-table>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils/";

  export default Vue.extend({
    name: "PaymentTransactions",

    data: function () {
      return {
        formatColumn: Utils.formatColumn,
        accounts: [],

        date: { from_date: "", to_date: "" },
        code: "",
        dialogFromModal: false,
        dialogToModal: false,
        advanced: false,

        // Hard coded data - no backend call for this
        directionItems: [
          { text: "Both", value: null },
          { text: "To", value: "out" },
          { text: "From", value: "in" },
        ],
        typeItems: [
          { text: "Any", value: null },
          { text: "Organisation", value: "organisation" },
          { text: "Flash", value: "flash" },
          { text: "Kazang", value: "kazang" },
          { text: "Card", value: "card" },
          { text: "Bank Account", value: "bank_account" },
          { text: "Safe", value: "safe" },
        ],

        directionModel: null,
        typesModel: null,
        showFilterInput1: false,
        showFilterInput2: false,
        showFilterBtn: false,
        showResetBtn: false,
        showFilters: [],
        filterInput1: null,
        filterInput2: null,
        filterInput1Label: null,
        filterInput2Label: null,
        filterInput1Value: null,
        filterInput2Value: null,
        cols: 6,

        headers: [
          { text: "Transaction Id", value: "id", sortable: false },
          { text: "Date", value: "created", type: "dateTime", sortable: false },
          {
            text: "Amount",
            value: "amount",
            type: "currency",
            sortable: false,
            class: "currency",
          },
          {
            text: "Profit",
            value: "profit",
            type: "currency",
            sortable: false,
            class: "currency",
          },
          {
            text: "Fee",
            value: "fee",
            type: "currency",
            sortable: false,
            class: "currency",
          },
          { text: "Payee", value: "payee", sortable: false },
          { text: "Transaction Type", value: "typeName", sortable: false },
          { text: "Status", value: "statusName", sortable: false },
          { text: "Reference", value: "reference", sortable: false },
          { text: "Issued by", value: "issuedByUser", sortable: false },
          { text: "Account", value: "accountName", sortable: false },
        ],

        showFilterSelect1: false,
        searchString: "",
        searchedOrganisation: "",
      };
    },

    computed: {
      _pagination: {
        get() {
          return this.pagination;
        },
        set(value) {
          this.$store.dispatch("transactions/paginate", value);
        },
      },
      transactions: function () {
        return this.$store.state.transactions.transactions;
      },
      pagination: function () {
        return this.$store.state.transactions.pagination;
      },
      totalItems: function () {
        return this.$store.state.transactions.totalItems;
      },
      loading: function () {
        return this.$store.state.transactions.loading;
      },
      searchedOrg: function () {
        return this.$store.state.transactions.searchedOrg;
      },
      startDate: function () {
        const d = new Date();
        let month = "" + (d.getMonth() - 2);
        let day = "" + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
          month = "0" + month;
        }
        if (day.length < 2) {
          day = "0" + day;
        }

        const date = [year, month, day].join("-");
        return date;
      },
    },

    watch: {
      pagination: function () {
        this.$store.dispatch("transactions/getTransactions", {
          direction: this.directionModel,
          counter_party_method_key: this.typesModel,
          from_date: this.date.from_date,
          to_date: this.date.to_date,
        });
      },
    },

    methods: {
      onPagenationChange(pagination) {
        this.$store.dispatch("transactions/getTransactions", {
          direction: this.directionModel,
          counter_party_method_key: this.typesModel,
          from_date: this.date.from_date,
          to_date: this.date.to_date,
        });
      },
      async getAccounts() {
        const response = await Accounts.getAccounts();
        this.accounts = response.data.results;
      },
      isAmountPositive(item) {
        return item.bias === "cr";
      },
      getStatusColor(status) {
        let color = "";
        switch (status.toLocaleLowerCase()) {
          case "success":
          case "depositing":
            color = "green--text";
            break;

          case "withdrawing":
          case "cancelled":
          case "failed":
            color = "red--text";
            break;

          case "pending":
          case "pending_auth":
            color = "orange--text";
            break;

          case "reviewing_withdrawal":
          case "reviewing_deposit":
            color = "light-blue--text";
            break;
        }

        return color;
      },
      formatDate(val) {
        return Utils.formatText(val, Utils.TextType.DATE_TIME);
      },
      formatCurrency(val) {
        return Utils.formatText(val, Utils.TextType.CURRENCY);
      },

      getPayee(item) {
        let payee = null;

        switch (item.counterParty.methodKey) {
          case "account":
            payee = item.counterParty.method.account.name;
            break;

          case "organisation":
            payee = `${item.counterParty.method.organisation.name} - ${item.counterParty.method.organisation.code}`;
            break;

          case "user":
            payee = item.counterParty.method.user.name;
            break;

          case "flash":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.flash.msisdn}`;
            break;

          case "kazang":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.kazang.accountNumber}`;
            break;

          case "card":
            payee = item.counterParty.methodName;
            break;

          case "bank_account":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.bankAccount.accountNumber}`;
            break;

          case "safe":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.safe.safeSerialNr}`;
            break;

          case "mama_money":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.mamaMoney.orderNumber}`;
            break;

          case "bondicell":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.bondicell.accountNumber}`;
            break;

          case "cash":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.cash.provider.name}`;
            break;

          case "eft":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.eft.provider.name}`;
            break;

          case "topitup":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.topItUp.accountNumber}`;
            break;

          case "cellair":
            payee = `${item.counterParty.methodName} - ${item.counterParty.method.cellAir.accountNumber}`;
            break;
        }

        return payee;
      },
      checkConditional() {
        this.showFilterBtn = true;

        this.clearConditionalFields();

        switch (this.typesModel) {
          // 2 fields
          case "organisation":
            this.showFilterSelect1 = true;
            this.cols = 4;
            /* this.showFilterInput1 = true;
            this.showFilterInput2 = true;
            this.cols = 3;

            this.filterInput1Label = "Code";
            this.filterInput2Label = "Name";
            this.filterInput1Value = "counter_party_code";
            this.filterInput2Value = "counter_party_name"; */
            break;

          case "flash":
            this.showFilterInput1 = true;
            this.showFilterInput2 = true;
            this.cols = 3;

            this.filterInput1Label = "MSISDN";
            this.filterInput2Label = "Transaction ID";
            this.filterInput1Value = "counter_party_msisdn";
            this.filterInput2Value = "counter_party_transaction_id";
            break;

          case "kazang":
            this.showFilterInput1 = true;
            this.showFilterInput2 = true;
            this.cols = 3;

            this.filterInput1Label = "Account Number";
            this.filterInput2Label = "Transaction ID";
            this.filterInput1Value = "counter_party_account_number";
            this.filterInput2Value = "counter_party_transaction_id";
            break;

          case "card":
            this.showFilterInput1 = true;
            this.showFilterInput2 = true;
            this.cols = 3;

            this.filterInput1Label = "Device ID";
            this.filterInput2Label = "Number";
            this.filterInput1Value = "counter_party_device_id";
            this.filterInput2Value = "counter_party_card_number";
            break;

          // 1 field
          case "user":
            this.showFilterInput1 = true;
            this.showFilterInput2 = false;
            this.cols = 4;

            this.filterInput1Label = "Name";
            this.filterInput2Label = "";
            this.filterInput1Value = "counter_party_name";
            break;

          case "bank_account":
            this.showFilterInput1 = true;
            this.showFilterInput2 = false;
            this.cols = 4;

            this.filterInput1Label = "Account Number";
            this.filterInput2Label = "";
            this.filterInput1Value = "counter_party_account_number";
            break;

          case "safe":
            this.showFilterInput1 = true;
            this.showFilterInput2 = false;
            this.cols = 4;

            this.filterInput1Label = "Safe Serial Number";
            this.filterInput2Label = "";
            this.filterInput1Value = "counter_party_safe_serial_number";
            break;

          // 0 fields
          case "bank_transfer":
          case "sfx":
            this.showFilterInput1 = false;
            this.showFilterInput2 = false;
            this.cols = 4;
            break;

          default:
            break;
        }
      },
      clearConditionalFields() {
        this.showFilterSelect1 = false;
        this.searchedOrganisation = "";
        this.showFilterInput1 = false;
        this.showFilterInput2 = false;
        this.filterInput1Label = "";
        this.filterInput2Label = "";
        this.filterInput1 = null;
        this.filterInput2 = null;
        this.filterInput1Value = null;
        this.filterInput2Value = null;
      },
      resetFilters() {
        this.directionModel = null;
        this.typesModel = null;
        this.showFilterBtn = false;
        this.showResetBtn = false;
        this.showFilterSelect1 = false;
        this.searchString = "";
        this.searchedOrganisation = "";
        this.showFilterInput1 = false;
        this.showFilterInput2 = false;
        this.filterInput1Label = "";
        this.filterInput2Label = "";
        this.filterInput1Value = null;
        this.filterInput2Value = null;
        this.cols = 6;
        this.showFilters = [];

        this.date = { from_date: "", to_date: "" };

        this.code = null;

        this.$store.dispatch("transactions/getTransactions", {
          direction: this.directionModel,
          counter_party_method_key: this.typesModel,
        });
      },
      filterTransactions() {
        this.showResetBtn = true;
        this.showFilters = [];

        if (this.showFilterSelect1) {
          this.$store.dispatch("transactions/getTransactions", {
            direction: this.directionModel,
            counter_party_method_key: this.typesModel,
            counter_party_id: this.searchedOrg.id,
            from_date: this.date.from_date,
            to_date: this.date.to_date,
          });
        } else if (this.filterInput1Value && this.filterInput2Value) {
          this.$store.dispatch("transactions/getTransactions", {
            direction: this.directionModel,
            counter_party_method_key: this.typesModel,
            [this.filterInput1Value]: this.filterInput1,
            [this.filterInput2Value]: this.filterInput2,
            from_date: this.date.from_date,
            to_date: this.date.to_date,
            counter_party_code: this.code == null ? null : this.code,
          });
        } else if (this.filterInput1Value) {
          this.$store.dispatch("transactions/getTransactions", {
            direction: this.directionModel,
            counter_party_method_key: this.typesModel,
            [this.filterInput1Value]: this.filterInput1,
            from_date: this.date.from_date,
            to_date: this.date.to_date,
            counter_party_code: this.code == null ? null : this.code,
          });
        } else if (this.filterInput2Value) {
          this.$store.dispatch("transactions/getTransactions", {
            direction: this.directionModel,
            counter_party_method_key: this.typesModel,
            [this.filterInput2Value]: this.filterInput2,
            from_date: this.date.from_date,
            to_date: this.date.to_date,
            counter_party_code: this.code == null ? null : this.code,
          });
        } else {
          this.$store.dispatch("transactions/getTransactions", {
            direction: this.directionModel,
            counter_party_method_key: this.typesModel,
            from_date: this.date.from_date,
            to_date: this.date.to_date,
            counter_party_code: this.code == null ? null : this.code,
          });
        }

        this.showFilters.push(
          this.directionModel == null
            ? "Both"
            : this.directionModel == "out"
            ? "To"
            : "From"
        );
        this.showFilters.push(
          this.typesModel == null ? "Any" : this.ucfirst(this.typesModel)
        );
        if (this.filterInput1 != null && this.filterInput1 != "")
          this.showFilters.push(this.filterInput1);
        if (this.filterInput2 != null && this.filterInput2 != "")
          this.showFilters.push(this.filterInput2);
      },
      ucfirst(s) {
        if (typeof s !== "string") return "";
        let str = s.replace(/_/g, " ");
        return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        });
      },

      async searchOrg() {
        const result = await this.$store.dispatch(
          "transactions/getOrganisationByCode",
          this.searchString
        );
        this.searchedOrganisation = result
          ? `${this.searchedOrg.name} - ${this.searchedOrg.code}`
          : "Organisation not found.";
        this.cols = 3;
      },

      clearOrg() {
        this.searchString = "";
        this.searchedOrganisation = "";
        this.cols = 4;
      },
    },
  });
</script>

<style scoped>
  .currency {
    min-width: 200px;
  }
</style>
